import "./style.css"
import React, { useEffect, useMemo, useState } from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PuffLoading from "components/Loading/PuffLoading"
import { Col, Form, Input, Label, Row } from "reactstrap"
import { useLazyQuery, useMutation } from "@apollo/client"
import PaginatedTable from "components/Table/PaginatedTable"
import ShipmentTable from "components/Table/ShipmentTable"
import OvalLoading from "components/Loading/OvalLoading"
import { GET_ORDER_PRODUCTS_BY_ID } from "graphql/queries/orders"
import {
  ADD_ORDER_NOTES,
  SEND_ORDER_EMAIL,
  UPDATE_ORDER_PRODUCT_PARTNER,
  UPDATE_ORDER_PRODUCT_STATUS,
} from "graphql/mutations/order"
import { CREATE_SHIPMENT } from "graphql/mutations/shipment"
import { PRINT_LABELS } from "graphql/mutations/label"
import useCustomMutation from "graphql/hooks/useCustomMutation"
import { toast } from "react-toastify"
import _ from "lodash"
import { GET_ALL_DELIVERY_PARTNERS } from "graphql/queries/partner"
import { DELIVERY_TIMES } from "utils/deliveryTimes"
import { CREATE_LABELS } from "graphql/mutations/labelPrinting"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useParams, useNavigate } from "react-router-dom"
import { LINKS } from "utils/links"
import ShipmentLoader from "components/Common/ShipmentLoader"
import ConfirmationDialog from "components/Common/ConfirmationDialog"
import PrintLabelModel from "components/Common/PrintLabelModel"
import ExistingShipmentsTable from "components/Table/ExistingShipmentsTable"
import ShipmentConfirmationForDusselDrop from "components/Common/ShipmentConfirmation/DusselDrop"
import ShipmentConfirmationForLogique from "components/Common/ShipmentConfirmation/Logique"
import ShipmentConfirmationForDPD from "components/Common/ShipmentConfirmation/Dpd"
import { PARTNERS, SHIPMENT_SUPPLIER } from "utils/constants"
import {formatDate, getPickupAndDeliveryDays, getPickupDay} from "utils/generator"

const ORDER_STATUS = ["processing", "picked", "completed"]

function OrderProcessing() {
  const { id } = useParams()
  const navigate = useNavigate()
  const formState = {
    order_id: "",
    quantity: "",
    partner: 68,
  }
  const [orderId, setOrderId] = useState("")
  const [orderNotes, setOrderNotes] = useState("")
  const [orderData, setOrderData] = useState()
  const [customerData, setCustomerData] = useState([])
  const [groupedData, setGroupedData] = useState({})
  const [deliveryPartnersData, setDeliveryPartnersData] = useState([])
  const [labels, setLabels] = useState([])

  const [updateStatus] = useCustomMutation(UPDATE_ORDER_PRODUCT_STATUS)
  const [updatePartner] = useCustomMutation(UPDATE_ORDER_PRODUCT_PARTNER)
  const [addOrderNotes] = useMutation(ADD_ORDER_NOTES)
  const [addShipment] = useMutation(CREATE_SHIPMENT)
  const [printLabel] = useMutation(PRINT_LABELS)
  const [sendEmail, { loading: sendEmailLoading }] =
    useCustomMutation(SEND_ORDER_EMAIL)
  const [createLabel, { loading: labelLoading }] = useMutation(CREATE_LABELS)
  const [shipment, setShipment] = useState([])
  const [selectedId, setSelectedId] = useState([])
  const [moveSelectedData, setmoveSelectedData] = useState([])
  const [shipmentData, setShipmentData] = useState([])
  const [paylodArray, setPlayloadArray] = useState([])
  const [isShipmentCreating, setShipmentLoader] = useState(false)

  const [showShipmentConfirmModel, setShowShipmentConfirmModel] = useState(false)
  const [showPdfModel, setShowPdfModel] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isAllowHideSection, setHideSections] = useState(false)
  const [labelBlob, setLabelBlob] = useState("");

  const [shipmentIds, setCreatedShipmentIds] = useState([])
  const [shipmentDeliveryPartners, setDeliveryPartnersOfCreatedShipment] = useState([])

  const [printLabelShipmentId, setPrintLabelShipmentId] = useState(null)
  const [printLabelLoader, setPrintLabelLoader] = useState(false)

  const [orderShipments, setOrderShipments] = useState([])
  const [orderShipmentsSize, setOrderShipmentsSize] = useState([])
  const [isExistingShipment, setIsExistingShipment] = useState(false)

  const [showPackageDetailModel, setShowPackageDetailModel] = useState(false)
  const [activeShipmentDetail, setShipmentDetail] = useState(null)
  const [customerDetail, setCustomerDetail] = useState(null)
  const [deliveryPartner, setDeliveryPartner] = useState(null)
  const [activeShipmentId, setActiveShipmentId] = useState(null)
  const [activeShipmentIndex, setActiveShipmentIndex] = useState(null)
  const [packageInformationData, setPackageInformationData] = useState([])

  const [confirmShipmentIds, setConfirmShipmentIds] = useState([])

  const [finalShipmentPayloadData, setFinalShipmentPayloadData] = useState([])

  const [oldPayloadArray, setOldPayloadArray] = useState([])

  // console.log('Final finalShipmentPayloadData ==>', finalShipmentPayloadData)


  const [products, { loading, refetch }] = useLazyQuery(GET_ORDER_PRODUCTS_BY_ID, {
    onCompleted: data => {
      setOrderNotes(data?.GetProductOrdersById?.notes || "")
      setOrderData(data?.GetProductOrdersById)

      setLabels([])
      if (data?.GetProductOrdersById?.shipping) {
        setCustomerData([data?.GetProductOrdersById?.shipping])
        setCustomerDetail(data?.GetProductOrdersById?.shipping)
      } else {
        setCustomerData([])
      }
      let groupedBy = _.groupBy(
        data?.GetProductOrdersById?.items,
        "deliveryPartner"
      )
      delete groupedBy["---"]
      setGroupedData(groupedBy)

      if (data?.GetProductOrdersById?.shipments) {

        setOrderShipmentsSize(data?.GetProductOrdersById?.shipments?.map((item, index) => index + 1));

        setIsExistingShipment(true)

        setOrderShipments((prev) => {
          return ([
            ...prev,
            ...data?.GetProductOrdersById?.shipments?.map((shipment) => ({ "items": shipment?.product_id, "shipmentId": shipment?.shipment_id, "delivery_partner": shipment?.delivery_partner, "print_lable_status": shipment?.label_status ?? false }))
          ])
        }
        )
      }

    },
  })

  const [deliveryPartners] = useLazyQuery(GET_ALL_DELIVERY_PARTNERS, {
    onCompleted: data => {
      setDeliveryPartnersData([{ name: "---" }, ...data.DeliveryPartners])
    },
  })

  const getProductsHandle = e => {
    e.preventDefault()
    if (!orderId) {
      setOrderData("")
      return toast.warn("Enter Order ID")
    }
    navigate(`${LINKS.orders}/${orderId}`)
  }

  const sendEmailHandle = () => {
    if (!orderId) return toast.warn("Enter Order ID")
    sendEmail("sendEmail", {
      input: { emailData: JSON.stringify(groupedData) },
      orderId,
    }).then(res => {
      toast.success("Email sent successfully")
      setOrderId("")
      setOrderData("")
      setCustomerData([])
      setGroupedData({})
      navigate(`${LINKS.orders}`)
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: JSON.parse(JSON.stringify(formState)),
    validationSchema: Yup.object({
      quantity: Yup.number()
        .required()
        .required("Please Enter Quantity")
        .positive()
        .integer(),
      partner: Yup.number()
        .required()
        .required("Please Select Partner")
        .positive()
        .integer(),
    }),
    onSubmit: values => {
      const formBody = {
        order_id: parseInt(orderId),
        quantity: values.quantity,
        partner: parseInt(values.partner),
      }
      createLabel({
        variables: {
          input: formBody,
        },
      }).then(res => {
        if (res.data.CreateLabels) {
          setLabels(res.data.CreateLabels)
        }
      })
    },
  })

  const notesSubmitHandle = e => {
    e.preventDefault()
    if (!orderId) return toast.warn("Enter Order ID")
    
    if (!orderNotes || orderNotes.trim() === "") {
        return toast.warn("Please add valid Note")
    } 
    addOrderNotes({
      variables: {
        id: parseInt(orderId),
        input: { notes: orderNotes },
      },
    }).then(resp => {
      if (resp?.data?.AddOrderNotes) {
        toast.success("Notes Added")
      }
    })
  }

  useEffect(() => {
    setmoveSelectedData(orderData?.items)
  }, [orderData])

  useEffect(() => {
    deliveryPartners()
  }, [id])

  useEffect(() => {
    if (!id) return

    setOrderId(parseInt(id))
    products({
      variables: {
        getProductOrdersById: parseInt(id),
      },
    })
  }, [id])

  const setCustomTimeHandle = (id, partner, deliveryTime) => {
    setGroupedData(prevGroupedData => {
      const updatedGroupedData = { ...prevGroupedData }
      const partnerGroup = updatedGroupedData[partner]

      if (partnerGroup) {
        const updatedPartnerGroup = partnerGroup.map(item =>
          item._id === id ? { ...item, deliveryTime } : item
        )
        updatedGroupedData[partner] = updatedPartnerGroup
      }

      return updatedGroupedData
    })
  }
  const shipmentHandle = () => {
    // const newArray = [];
    setHideSections(false);

    setIsExistingShipment(false)

    // setPlayloadArray(pre => [...pre, []])
    // const data = [...paylodArray?.map(subArray => subArray?.map(o => ({ ...o, isShipped: true }))), []]
    // setPlayloadArray(data);

    setPlayloadArray(pre => [...pre?.map(subArray => subArray?.map(o => ({ ...o, isShipped: true }))), []]);

    setShipment(pre => {
      if (pre.length > 0)
        return [...pre, pre[pre.length - 1] + 1]
      else
        return [1];
    })

    setConfirmShipmentIds(prev => [...prev, null]);
    setFinalShipmentPayloadData(prev => [...prev, null])

  }
  const selectedProducts = (value, flag) => {
    // if (flag) {
    //   setSelectedId(prevSelectedId => [...prevSelectedId, value])
    // } else {
    //   setSelectedId(prevSelectedId =>
    //     prevSelectedId.filter(item => item !== value)
    //   )
    // }

    setSelectedId((prevSelectedId) => {
      if (prevSelectedId.includes(value)) {
        return prevSelectedId.filter(id => id !== value);
      } else {
        return [...prevSelectedId, value];
      }
    });

  }

  // console.log('dm oldPayloadArray main =>', oldPayloadArray);

  const submitAllShipmentHandle = () => {

    // console.log('Final paylodArray ==>', paylodArray);

    if (!finalShipmentPayloadData.some(innerArray => innerArray?.package_info?.length > 0)) {
      toast.warn('Please add detail inside shipments');
      return
    }

    let res = getPickupAndDeliveryDays()

    setShipmentLoader(true);

    // let newPayloadArray = [...paylodArray];
    // newPayloadArray = newPayloadArray.filter(element => element.length > 0);
    let newPayloadArray = [...finalShipmentPayloadData.filter(o => Boolean(o))];

    const createShipmentArray = [];
    for (let i = 0; i < newPayloadArray.length; i++) {
      let subArray = [];
      let products_ids = [];
      if (newPayloadArray[i]?.package_info?.length > 0) {
        for (let j = 0; j < newPayloadArray[i]?.package_info?.length; j++) {

          let PickUpAddress = newPayloadArray[i].package_info[j].supplier ? SHIPMENT_SUPPLIER.find(o => o.value === newPayloadArray[i]?.package_info[j]?.supplier).PickUp : SHIPMENT_SUPPLIER[1].PickUp

          let packing = newPayloadArray[i]?.package_info[j]?.productName;

          if (newPayloadArray[i]?.deliveryPartner == 'Logique') {
            packing = 'Pakket klein'
          }

          subArray.push({
            Packing: packing,
            Barcode: "",
            Comments: "",
            Parts: newPayloadArray[i]?.package_info[j]?.packages?.length,
            Packages: newPayloadArray[i]?.package_info[j]?.packages, // volume added additionally
            Quantity: newPayloadArray[i]?.package_info[j]?.quantity,
            Sku: newPayloadArray[i]?.package_info[j]?.sku,
            PickUp: {
              ...PickUpAddress, // static address (will replace soon) - need client help for this
              // ...SHIPMENT_SUPPLIER.find(o => o.value === finalShipmentPayloadData[i]?.package_info[j]?.supplier).PickUp,
              Instructions: newPayloadArray[i]?.package_info[j]?.pickup_instruction, // "Ophaalinstructies" // think on it for both prtner may be diffrent payload
              // ReferenceOur: "#8746",
              // ReferenceYour: "ref-1234",
              Requested: {
                DateTimeBegin: formatDate(res.pickup.start), // "2020-01-04T09:00:00",
                DateTimeEnd: formatDate(res.pickup.end) // "2020-01-04T17:00:00",
              },
              ReferenceOur: `#${orderId}`,
              ReferenceYour: `ref-${orderId}`,
            },
            Supplier: newPayloadArray[i]?.package_info[j]?.supplier,

            // Parts: newPayloadArray[i][j].quantity,
            // Depth: 1.1,
            // Height: 1.1,
            // Width: 1.1,
            // VolumeWeight: 1.1,
            // ArticleWeight: 1.1,
            // Weight: 1.1,

          })
          console.log(subArray)
          products_ids.push(newPayloadArray[i]?.package_info[j]?.product_id,)
        }
      }

      const mainObject = {
        order_id: orderId,
        Contact: customerDetail?.name, // "Contact Persoon Logique",
        Notes: "Notities Logique",
        product_id: products_ids,
        GoodList: subArray,

        PickUp: subArray[0]?.PickUp, // tempororay pass here that should be access from above mention loop of subArray
        // PickUp: {
        //   Instructions: "Ophaalinstructies", // what to do with this now as pickup instruction are inside good list for each product
        //   ReferenceOur: "#8746",
        //   ReferenceYour: "ref-1234",
        //   Requested: {
        //     DateTimeBegin: formatDate(new Date()), // "2020-01-04T09:00:00",
        //     DateTimeEnd: formatDate(new Date()) // "2020-01-04T17:00:00",
        //   },
        // },

        Delivery: {
          ReferenceOur: `#${orderId}`,
          ReferenceYour: `ref-${orderId}`,
          Address: {
            Name: customerDetail?.name, // "Piet Klant",
            Premise: customerDetail?.address,  // "expeditie",
            Street: "Straatweg",
            Number: customerDetail?.house_number, // "123",
            PostalCode: customerDetail?.postal_code, //"1234 AB",
            Place: customerDetail?.city,// "Ede",
            Country: "Nederland",
            CountryCode: customerDetail?.country, // "NL",
          },
          ContactName: customerDetail?.name,  //"Piet Klant",
          Instructions: newPayloadArray[i]?.deliveryInfo?.delivery_instruction, // "Instructies afleveren",
          // ReferenceOur: "#8746",
          // ReferenceYour: "ref-1234",
          Requested: {
            DateTimeBegin: formatDate(res.delivery.start), // "2020-01-05T09:00:00",
            DateTimeEnd: formatDate(res.delivery.end) // "2020-01-05T17:00:00",
          },
          Connectivity: {
            Email: customerDetail?.email, //"piet@klant.nl",
            Phone: customerDetail?.telephone, //"012-2345678",
          },
        },
        DeliveryPartner: newPayloadArray[i]?.deliveryPartner,   // "Logique"

        // Only for DusselDrop partner required
        OrderType: newPayloadArray[i]?.deliveryInfo?.orderType,
        ServiceType: newPayloadArray[i]?.deliveryInfo?.service,
        Floor: newPayloadArray[i]?.deliveryInfo?.floor,
        Lift: newPayloadArray[i]?.deliveryInfo?.elevator,

      }

      createShipmentArray.push(mainObject)
    }
    // console.log('createShipmentArray =>', createShipmentArray)

    console.log(`Pickup Date DateTimeBegin: ${formatDate(res.pickup.start)}`)
    console.log(`Pickup Date DateTimeEnd: ${formatDate(res.pickup.end)}`)
    console.log(`Delivery Date DateTimeBegin: ${formatDate(res.delivery.start)}`)
    console.log(`Delivery Date DateTimeEnd: ${formatDate(res.delivery.end)}`)

    new Promise((resolve, reject) => {
      resolve(
        addShipment({
          variables: {
            input: createShipmentArray,
          }
        })
      )
    }).then(resp => {
      if (resp?.data?.createShipment) {
        toast.success("All Shipment has been shipped")

        setCreatedShipmentIds(prev => [...prev, ...resp?.data?.createShipment?.shipmentIds])
        setDeliveryPartnersOfCreatedShipment(prev => [...prev, ...resp?.data?.createShipment?.deliveryPartners])

        setHideSections(true)
        setFinalShipmentPayloadData([]) // added new
        // setConfirmShipmentIds([])

        const length = paylodArray?.length; //resp?.data?.createShipment?.shipmentIds?.length;
        const mainArray = [];
        for (let i = 0; i < length; i++) {
          mainArray.push([]);
        }

        setOldPayloadArray([...prev, ...paylodArray.filter(o => o.length)]) // added new dm
        setPlayloadArray(mainArray) // chpage position from top to bottom // temp 

        // setPlayloadArray([[]])

      }
    }).catch(err => {
      // consol.log('Shipment Error')
    }).finally(() => {
      setShipmentLoader(false)
    })
    // addShipment({variables: {
    //   input: createShipmentArray ,
    // }})
    // .then(resp =>{ 
    //  if(resp?.data?.createShipment){
    //    toast.success("All Shipment has been shipped")
    //  }
    // }
    // )
  }

  const handleTogglePdfModel = (shipmentId, shipmentDeliveryPartner) => {
    setPrintLabelShipmentId(shipmentId);
    setPrintLabelLoader(true)
    new Promise((resolve, reject) => {
      resolve(
        printLabel({
          variables: {
            input: {
              orderId: shipmentId,
              deliveryPartner: shipmentDeliveryPartner
            }
          }
        })
      )
    }).then(resp => {
      if (resp.data.createLabel.stream != null) {
        setLabelBlob(resp?.data?.createLabel?.stream)
        setShowPdfModel(!showPdfModel)
      }
    }).catch(err => {
      toast.error('Oops! Something went wrong while printing label')
    }).finally(() => {
      setPrintLabelLoader(false)
    })
  }

  const handleToggleShipmentConfirmModel = () => {
    setShowShipmentConfirmModel(!showShipmentConfirmModel)
  }

  const handleDelete = () => {
    setIsDelete(false)
  }

  const getModelStatus = () => {
    return showShipmentConfirmModel
  }

  const onConfirmClick = () => {
    setIsDelete(true)
    setShowShipmentConfirmModel(false)
  }

  const handleTogglePackagDetailModel = () => {
    setShowPackageDetailModel(!showPackageDetailModel)
  }

  const setShipmentDetailData = (detail, deliveryPartner, shipmentId, shipmentIndex) => {
    setShipmentDetail(detail)
    setDeliveryPartner(PARTNERS?.find(o => o.value == deliveryPartner)?.label)
    setActiveShipmentId(shipmentId)
    setActiveShipmentIndex(shipmentIndex)

  }

  const getFormData = (data) => {
    managePackageInformationData(data)
    handleTogglePackagDetailModel();

    // setConfirmShipmentIds(prev => ([...prev, data?.package_info[0]?.product_id]))

    const confirmShipmentIdsCopy = [...confirmShipmentIds];
    confirmShipmentIdsCopy[activeShipmentIndex] = data?.package_info[0]?.product_id
    setConfirmShipmentIds(confirmShipmentIdsCopy)

    // setFinalShipmentPayloadData(prev => ([...prev, { ...data }]))

    const finalShipmentPayloadDataCopy = [...finalShipmentPayloadData];
    finalShipmentPayloadDataCopy[activeShipmentIndex] = data
    setFinalShipmentPayloadData(finalShipmentPayloadDataCopy)

  }

  const managePackageInformationData = (data) => {
    setPackageInformationData(prev => ([...prev, {
      shipmentId: activeShipmentId,
      delivery_info: data?.deliveryInfo,
      customer_info: customerDetail,
      package_info: data?.package_info
    }]))
  }

  const columns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "product_id",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Name",
        accessor: "item.name",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: price => {
          return (
            <span>
              {price.value?.toFixed(2)} {price.cell.row.original.currency}
            </span>
          )
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: total => {
          return (
            <span>
              {total.value?.toFixed(2)} {total.cell.row.original.currency}
            </span>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: status => {
          return (
            <div className="branch_status">
              <select
                className="form-select"
                style={{ minWidth: 140, textTransform: "capitalize" }}
                data-id={status.row.original._id}
                onChange={e =>
                  updateStatus("updateStatus", {
                    input: { status: e.target.value },
                    productId: status.cell.row.original._id,
                    orderId: orderData?.order_id,
                  }).then(res => {
                    toast.success("Status Updated!")
                  })
                }
              >
                {ORDER_STATUS &&
                  ORDER_STATUS?.map((oStatus, i) => (
                    <option
                      key={i}
                      value={oStatus}
                      selected={
                        oStatus?.toLowerCase() === status?.value?.toLowerCase()
                      }
                    >
                      {oStatus}
                    </option>
                  ))}
              </select>
            </div>
          )
        },
      },
      {
        Header: "Estimated Time",
        accessor: "item.estimate",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Delivery Time",
        accessor: "deliveryTime",
        Cell: deliveryTime => {
          return (
            <div className="branch_status" style={{ maxWidth: 300 }}>
              <select
                className="form-select"
                style={{ minWidth: 140, textTransform: "uppercase" }}
                data-id={deliveryTime.row.original._id}
                onChange={e =>
                  setCustomTimeHandle(
                    deliveryTime.cell.row.original._id,
                    deliveryTime.cell.row.original.deliveryPartner,
                    e.target.value
                  )
                }
              >
                {DELIVERY_TIMES &&
                  DELIVERY_TIMES?.map(time => (
                    <option
                      key={time}
                      value={time}
                      selected={
                        time.toLowerCase() ===
                        deliveryTime?.value?.toLowerCase()
                      }
                    >
                      {time}
                    </option>
                  ))}
              </select>
            </div>
          )
        },
      },
    ],
    [orderData]
  )
  const shipmentColumns = useMemo(
    () => [
      {
        Header: "Product ID",
        accessor: "product_id",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Name",
        accessor: "item.name",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Price",
        accessor: "price",
        Cell: price => {
          return (
            <span>
              {price.value?.toFixed(2)} {price.cell.row.original.currency}
            </span>
          )
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: total => {
          return (
            <span>
              {total.value?.toFixed(2)} {total.cell.row.original.currency}
            </span>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: status => {
          return (
            <span>{status.value}</span>
          )
        },
      },
      {
        Header: "Estimated Time",
        accessor: "item.estimate",
        Cell: ({ value }) => {
          return <span>{value}</span>
        },
      },
      {
        Header: "Delivery Time",
        accessor: "deliveryTime",
        Cell: deliveryTime => {
          return (
            <span>{deliveryTime.value}</span>
          )
        },
      },
    ],
    [orderData]
  )

  const customerTableColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Telephone",
        accessor: "telephone",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Country",
        accessor: "country",
      },
    ],
    [customerData]
  )
  document.title = "Order | Order Processing"
 const renderShipmentConfirmation = () => {
    if(deliveryPartner === "Jos Dusseldorp") {
      return (
        <ShipmentConfirmationForDusselDrop
          show={showPackageDetailModel}
          tittle="Confirm Shipment"
          deliveryPartner={deliveryPartner}
          onCloseClick={handleTogglePackagDetailModel}
          onConfirmClick={getFormData}
          activeShipmentDetail={activeShipmentDetail}
          customerDetail={customerDetail}
          defaultValues={packageInformationData?.find(o => o.shipmentId === activeShipmentId)}
        /> 
      )
    }
    else if(deliveryPartner === "Logique") {
      return (<ShipmentConfirmationForLogique
        show={showPackageDetailModel}
        tittle="Confirm Shipment"
        deliveryPartner={'Logique'}
        onCloseClick={handleTogglePackagDetailModel}
        onConfirmClick={getFormData}
        activeShipmentDetail={activeShipmentDetail}
        customerDetail={customerDetail}
        defaultValues={packageInformationData?.find(o => o.shipmentId === activeShipmentId)}
      />)
    }
    else {
      return (<ShipmentConfirmationForDPD
        show={showPackageDetailModel}
        tittle="Confirm Shipment"
        deliveryPartner={'DPD'}
        onCloseClick={handleTogglePackagDetailModel}
        onConfirmClick={getFormData}
        activeShipmentDetail={activeShipmentDetail}
        customerDetail={customerDetail}
        defaultValues={packageInformationData?.find(o => o.shipmentId === activeShipmentId)}
      />)
    }
 }

  return (
    <div className="page-content order-processing">
      {(loading || sendEmailLoading || labelLoading) && <PuffLoading />}
      <div className="container-fluid">
        <Breadcrumbs title="Order Processing" breadcrumbItem="Order" />
        <div>
          <Row>
            <Col md={4} className="d-flex align-items-center">
              <form
                onSubmit={getProductsHandle}
                className="search-box d-flex align-items-center gap-2 mb-4"
              >
                <div className="position-relative">
                  <label htmlFor="search-bar-0" className="search-label mb-0">
                    <span id="search-bar-0-label" className="sr-only">
                      Enter Order ID
                    </span>
                    <input
                      onChange={e => {
                        setOrderId(e.target.value)
                      }}
                      id="search-bar-0"
                      type="number"
                      min={0}
                      className="form-control"
                      placeholder={`Enter Order ID`}
                      value={orderId}
                    />
                  </label>
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light btn btn-primary"
                >
                  Search
                </button>
              </form>
            </Col>
          </Row>

          <div className="position-relative order-table">
            {loading && <OvalLoading />}
            <h5>Customer Information</h5>
            <PaginatedTable
              columns={customerTableColumns}
              data={customerData || []}
              isLoading={loading}
            />

            {orderData && (
              <Row>
                <Col md={6} lg={6}>
                  <h5 className="mt-3">Order Notes</h5>
                  <form onSubmit={notesSubmitHandle}>
                    <div>
                      <textarea
                        className="form-control"
                        value={orderNotes}
                        style={{ minHeight: 100 }}
                        placeholder={`Enter Order Notes`}
                        onChange={e => setOrderNotes(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="mt-2 btn btn-primary waves-effect waves-light btn btn-primary"
                    >
                      Add Notes
                    </button>
                  </form>
                </Col>

              </Row>
            )}
            {isShipmentCreating && <ShipmentLoader />}

            {shipment.length && !isAllowHideSection ? <div style={{ textAlign: 'right' }}>
              <button className="btn btn-primary waves-effect waves-light btn btn-primary" disabled={shipment?.length !== confirmShipmentIds?.filter(o => Boolean(o))?.length} onClick={submitAllShipmentHandle} >Submit all shipment</button>
            </div> : null}


            {orderShipmentsSize?.length > 0 &&
              <div className="shipment-title-wrapper">
                <div className="shipment-title mb-3">

                </div>
                {/* Existing Shipments */}
                <div className='shipment-box-wrapper' >
                  {orderShipmentsSize.map((item, index) => {
                    return (
                      <ExistingShipmentsTable
                        key={index}
                        shipmentIndex={index}
                        shipmentValue={orderShipments[index]?.shipmentId}
                        shipmentDeliveryPartner={orderShipments[index]?.delivery_partner}
                        hasLiveShipmentId={true}
                        columns={shipmentColumns}
                        existingShipmentData={orderShipments?.length > 0 ? orderShipments[index]?.items : []}
                        isAllowHideSection={true}
                        onPrintLabelClick={handleTogglePdfModel}
                        isPrintLabelLoading={printLabelLoader}
                        isShowPrintLabel={(orderShipments[index]?.delivery_partner === 'Logique' || orderShipments[index]?.delivery_partner === 'DPD') ? true : orderShipments[index]?.print_lable_status}
                      />
                    )
                  })}
                </div>
              </div>
            }

            {
              shipment.length ? <div className="shipment-title-wrapper">
                <div className="shipment-title mb-3">

                </div>

                {/* <!-- Working --> */}
                <div className='shipment-box-wrapper' >
                  {shipment.length
                    ?
                    shipment?.map((item, index) => {
                      if (Boolean(item)) {
                        return (
                          <ShipmentTable
                            key={index}
                            shipment={shipment[index]} //
                            shipmentIndex={index}
                            shipmentValue={shipmentIds[index] ?? item}
                            hasLiveShipmentId={shipmentIds[index] ? true : false}
                            shipmentDeliveryPartner={shipmentDeliveryPartners[index]}
                            setShipment={setShipment}
                            columns={shipmentColumns}
                            data={shipmentData || []} //
                            selectedId={selectedId}

                            orderData={orderData?.items}

                            setOrderData={setOrderData}
                            setSelectedId={setSelectedId}
                            paylodArray={paylodArray}

                            setConfirmShipmentIds={setConfirmShipmentIds}
                            setFinalShipmentPayloadData={setFinalShipmentPayloadData}

                            // oldPayloadArray={oldPayloadArray[index]} // added new dm
                            oldPayloadArray={oldPayloadArray} // added new dm

                            setPlayloadArray={setPlayloadArray}

                            getModelConfirmation={handleToggleShipmentConfirmModel}
                            isDelete={isDelete}
                            handleDelete={handleDelete}
                            getModelStatus={getModelStatus}

                            isAllowHideSection={shipmentIds[index] ? true : false}
                            onPrintLabelClick={handleTogglePdfModel}
                            isPrintLabelLoading={printLabelLoader}
                            isShowPrintLabel={shipmentDeliveryPartners[index] === 'Logique' || shipmentDeliveryPartners[index] === 'DPD' ? true : false}

                            getPackageModel={handleTogglePackagDetailModel}
                            getActiveShipmentDetail={setShipmentDetailData}

                            // isLockRecord={isLockRecord}
                            isLockRecord={confirmShipmentIds[index] ? true : false}

                          />

                        )
                      }
                    })
                    : null}
                </div>
              </div> : null
            }

            {(orderData?.items?.length > 0) &&
              <>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "16px" }}>
                  <h5>Order Information</h5>
                  <button
                    className="btn btn-primary waves-effect waves-light btn btn-primary"
                    onClick={shipmentHandle}
                  // type="submit"
                  >
                    + Add Shipment
                  </button>

                </div>

                <PaginatedTable
                  columns={columns}
                  data={orderData?.items || []}
                  isLoading={loading}
                  showCheckbox={true}
                  selectedProducts={selectedProducts}

                  selectedIds={selectedId} //
                />

                <button
                  onClick={sendEmailHandle}
                  className="btn btn-primary waves-effect waves-light btn btn-primary"
                >
                  Send Email
                </button>

              </>
            }
          </div>

        </div>
      </div>

      <ConfirmationDialog
        show={showShipmentConfirmModel}
        tittle={'Confirmation Dialogue'}
        description={'Are you sure to remove this product ?'}
        onCloseClick={handleToggleShipmentConfirmModel}
        onConfirmClick={onConfirmClick}
      />

      <PrintLabelModel
        show={showPdfModel}
        pdfBlob={labelBlob ? labelBlob : null}
        tittle={`Print Label For Shipment #${printLabelShipmentId}`}
        onCloseClick={() => setShowPdfModel(false)}
      />
      {renderShipmentConfirmation()}

    </div>
  )
}

export default OrderProcessing
